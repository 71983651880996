<!--
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-05-18 10:47:20
 * @LastEditors: hutian
 * @LastEditTime: 2021-08-05 19:58:36
-->
<template>
<div class='edit-address'>
  <a-modal title="修改地址" v-model="visible" :width="800" @ok="onConfirm" okText="确定" :maskClosable="false">
    <a-form-model ref="form" :model="rowData" :rules="formRule" :label-col="{span: 3}" :wrapper-col="{span: 21}">
      <a-form-model-item label="客户名" prop="customerName">
        <a-input v-model="rowData.customerName" placeholder="客户名"></a-input>
      </a-form-model-item>
      <a-form-model-item label="联系方式" prop="customerContact">
        <a-input v-model="rowData.customerContact" placeholder="客户联系方式"></a-input>
      </a-form-model-item>
      <a-form-model-item label="收货地址" prop="provinceId">
        <PCD
          ref="pcd"
          placeholder="请选择区域"
          :province.sync="rowData.provinceName"
          :city.sync="rowData.cityName"
          :district.sync="rowData.areaName"
          :provinceId.sync="rowData.provinceId"
          :cityId.sync="rowData.cityId"
          :districtId.sync="rowData.areaId">
        </PCD>
      </a-form-model-item>
      <a-form-model-item label="详细地址" prop="address">
        <a-textarea v-model="rowData.address" placeholder="详细收货地址" maxLength="50"></a-textarea>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</div>
</template>

<script>

export default {
  name:'',
  data() {
    return {
      visible: false,
      rowData: {},
      // 表单验证
            formRule: {
                provinceId: [
                  { required: true, message: '请选择收货地址', trigger: 'change' }
                ],
                customerName: [
                    { required: true, message: '请输入客户名', trigger: 'blur' }
                ],
                customerContact: [
                    { required: true, pattern: new RegExp(/^((1[3456789]\d{9})|(18[0,5-9]))\d{8}$|^0\d{2,3}-?\d{7,8}$/), message: '请输入客户联系方式', trigger: 'blur' }
                ],
                address: [
                    { required: true, message: '请输入详细收货地址', trigger: 'blur' }
                ]
            },
    }
  },

  computed: {

  },
  created() {

  },
  methods: {
    isShow(row) {
      this.visible = true
      this.rowData = row
      // this.$set(this.rowData, 'provinceId', this.rowData.provinceId)
      // this.$set(this.rowData, 'cityId', this.rowData.cityId)
      // this.$set(this.rowData, 'countyId', this.rowData.countyId)
      this.$nextTick(() => {
          this.$refs.pcd.setPCD()
      })
    },

    onConfirm() {
      this.$refs.form.validate(async valid => {
        if (!valid) {
          return
        }
        this.axios.post(`/api/order/order/orderInfo/editOrderInfoAddress`, this.rowData).then(res => {
          if(res.code) {
            this.visible = false
            this.$emit('reload')
            this.$message.success(res.message)
          }
        })
      })
    }

  }
}
</script>

<style lang='scss' scoped>
</style>